<template>
  <header
    class="header"
    :class="{ act : isShowGnb }"
  >
    <div class="header__inner">
      <div class="header__left">
        <Transition name="slide-left">
          <button
            v-if="isShowTitle && !isShowGnb"
            class="header__back-button"
            @click="handleClickBackBtn"
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1181_2191"
                style="mask-type:alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="40"
                height="40"
              >
                <rect
                  width="40"
                  height="40"
                  fill="#000"
                />
              </mask>
              <g mask="url(#mask0_1181_2191)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.4142 18.5858L14 20L15.4142 21.4142L24 30L25.4142 28.5858L16.8284 20L25.4142 11.4142L24 10L15.4142 18.5858Z"
                  fill="#000"
                />
              </g>
            </svg>
          </button>
        </Transition>
        <Transition name="logo">
          <nuxt-link
            v-if="isShowLogo || isShowGnb"
            :to="localePath('/')"
            class="header__title header__title--black"
            @click="handleClickLogoBtn"
          >Experience IONIQ 6</nuxt-link>
        </Transition>
      </div>
      <Transition name="slide-down">
        <div
          v-if="isShowTitle && !isShowGnb"
          class="header__center"
        >
          <h1 class="header__sub-title header__sub-title--black">{{ store.title }}</h1>
        </div>
      </Transition>
      <div class="header__right">
        <button
          class="header__hamburger"
          :class="{ act : isShowGnb }"
          type="button"
          @click="handleToggleGNB"
        >
          <span>메뉴 열기</span>
          <svg
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
            class="icon"
          >
            <rect class="top" />
            <rect class="mid" />
            <rect class="btm" />
          </svg>
        </button>
      </div>
    </div>
    <Transition name="gnb">
      <Gnb
        v-show="isShowGnb"
        :is-show-gnb="isShowGnb"
        @closeGnb="handleToggleGNB"
      />
    </Transition>
  </header>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useHeaderStore } from '@/stores/header'

const localePath = useLocalePath()
const router = useRouter()
const pathUrl = ref('')
const beforeOffset = ref(0)
const isScrollDown = ref(false)
const store = useHeaderStore()

const isShowGnb = computed(() => store.isShowGnb)
const isShowTitle = computed(() => store.titleVisible)
const isShowLogo = computed(() => store.logoVisible)

onMounted(() => {
  window.addEventListener('scroll', onScrollOffset)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScrollOffset)
})

const onScrollOffset = () => {
  isScrollDown.value = window.scrollY > beforeOffset.value
  beforeOffset.value = window.scrollY
  handleToggleScrolledClass()
}

const handleToggleGNB = () => {
  store.showGnb(!isShowGnb.value)
}

const handleClickLogoBtn = () => {
  if (isShowGnb.value) {
    handleToggleGNB()
  }
}

const handleClickBackBtn = () => {
  if (store.backBtnLink !== '') {
    pathUrl.value = store.backBtnLink
    router.push({ path: localePath(pathUrl.value) })
  }
}

const handleToggleScrolledClass = () => {
  const body = document.body
  if (window.scrollY > 0) {
    body.classList.add('scrolled')
    if (isScrollDown.value) {
      body.classList.add('down')
      body.classList.remove('up')
    } else {
      body.classList.add('up')
      body.classList.remove('down')
    }
  } else {
    body.classList.remove('scrolled')
    body.classList.remove('up')
    body.classList.remove('down')
  }
}
</script>

<style lang="scss" scoped>
.slide-down-enter-active,
.slide-down-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.slide-down-enter-to,
.slide-down-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.slide-left-enter-active,
.slide-left-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}

.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

.slide-left-enter-to,
.slide-left-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.logo-enter-from,
.logo-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.logo-enter-to,
.logo-leave-from {
  transform: translateX(0);
  opacity: 1;
}
</style>