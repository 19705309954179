<template>
  <transition name="fade">
    <div
      v-show="isShow"
      class="popup"
    >
      <div class="popup__dim" />
      <div class="popup__box">
        <div class="popup__body">
          <h2>{{ $t('component.SharePopup.001') }}</h2>
          <div class="content">
            <ul class="sns_share_list">
              <li>
                <a
                  :href="
                    'https://www.facebook.com/sharer/sharer.php?u=' + getUrlSNS
                  "
                  target="_blank"
                  class="facebook"
                >share to facebook</a>
              </li>
              <li>
                <a
                  :href="
                    'https://twitter.com/intent/tweet?url=' + getUrlSNS + '&text='
                  "
                  target="_blank"
                  class="twitter"
                >share to twitter</a>
              </li>
              <li>
                <a
                  href="mailto:?subject=[Invitation] Visit and Experience IONIQ 6&amp;body=Sharing from IONIQ 6 experience web!%0D%0AVisit and experience the key features of our new IONIQ 6!%0D%0Ahttps://experienceioniq6.hyundai.com/"
                  class="email"
                >
                  share to email
                </a>
              </li>
            </ul>
            <div class="copy_link_to_clipboard">
              <input
                id="inputCopyURL"
                ref="inputCopyURL"
                class="fs_sans_text"
                type="text"
                :value="getUrl"
              >
              <button @click="copyLinkToClipboard" />
            </div>
          </div>
        </div>
        <div class="popup__footer">
          <Button
            :title="$t('component.SharePopup.002')"
            type="primary"
            @handleClick="close"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { useSettingsStore } from '@/stores/settings'
import { useRoute } from 'vue-router'

const localePath = useLocalePath()
const store = useSettingsStore()
const route = useRoute()

const isShow = computed(() => store.isShowSharePopup)
const runtimeConfig = useRuntimeConfig()

console.log('runtimeConfig')
console.log(runtimeConfig)

const baseUrl = runtimeConfig.public.baseURL
const getUrl = computed(() => {
  return `${baseUrl}${localePath(route.fullPath)}`
})
const getUrlSNS = computed(() => process.env.BASE_URL)

const inputCopyURL = ref(null)

const close = () => {
  store.toggleSharePopup()
}

const copyLinkToClipboard = () => {
  if (inputCopyURL.value !== null) {
    inputCopyURL.value.select()
    inputCopyURL.value.setSelectionRange(0, 99999)
    document.execCommand('copy')
    alert('Link has been copied.')
  }
}
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  &__dim {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }

  &__box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    max-width: 343px;
    background: rgba(255, 255, 255, 1);
  }

  &__body {
    flex-grow: 1;
    padding: 48px 40px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #000;
    }
    .content {
      flex-grow: 1;
      margin-top: 32px;
      .sns_share_list {
        display: flex;
        gap: 24px;
        align-items: center;
        align-content: center;
        justify-content: center;
        li {
          button,
          a {
            display: block;
            width: 40px;
            height: 40px;
            overflow: hidden;
            text-indent: -9999px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: transparent;
            border: 0;
            &.facebook {
              background-image: url("~/assets/images/components/popup/facebook.svg");
            }
            &.twitter {
              background-image: url("~/assets/images/components/popup/twitter.svg");
            }
            &.email {
              background-image: url("~/assets/images/components/popup/email.svg");
            }
          }
        }
      }
      .copy_link_to_clipboard {
        display: flex;
        margin-top: 40px;
        input[type="text"] {
          width: 100%;
          background-color: #e1dfdf;
          border: 0px;
          padding: 11px 15px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #000;
          border-radius: 0;
        }
        button {
          flex-shrink: 0;
          background-color: #002c5f;
          border: 0px;
          width: 48px;
          height: 48px;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8284 8.17157C10.2663 6.60948 7.73367 6.60948 6.17157 8.17157L2.17157 12.1716C0.609476 13.7337 0.609476 16.2663 2.17157 17.8284C3.73367 19.3905 6.26633 19.3905 7.82843 17.8284L8.92999 16.7269M8.17157 11.8284C9.73367 13.3905 12.2663 13.3905 13.8284 11.8284L17.8284 7.82843C19.3905 6.26633 19.3905 3.73367 17.8284 2.17157C16.2663 0.609476 13.7337 0.609476 12.1716 2.17157L11.072 3.27118' stroke='white' stroke-width='2' stroke-linecap='square' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
      }
    }
  }

  &__footer {
    padding: 0 40px 48px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (min-width: 768px) {
  .popup {
    &__box {
      max-width: 400px;
    }
    &__body {
      padding: 80px 40px 40px;
      h2 {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
}
</style>
