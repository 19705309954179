<template>
  <nav class="gnb">
    <div class="lo_mo">
      <ul>
        <li>
          <button
              type="button"
              class="acd"
              :class="{ opn: isOpen.story }"
              @click="isOpen.story = !isOpen.story"
          >
            {{ t('component.Gnb.001') }}
          </button>
          <Transition name="gnb-sub">
            <ul v-show="isOpen.story">
              <li>
                <NuxtLink :to="localePath('owners-world')" @click="handleClick">
                  {{ t('component.Gnb.002') }}
                </NuxtLink>
              </li>
              <li>
                <NuxtLink :to="localePath('story')" @click="handleClick">
                  {{ t('component.Gnb.003') }}
                </NuxtLink>
                <ul>
                  <li>
                    <NuxtLink :to="localePath('/story/intro')" @click="handleClick">
                      {{ t('component.Gnb.004') }}
                    </NuxtLink>
                  </li>
                  <li>
                    <NuxtLink :to="localePath('/story/ep01')" @click="handleClick">
                      {{ t('component.Gnb.005') }}
                    </NuxtLink>
                    <ul>
                      <li>
                        <NuxtLink
                            :to="localePath('/story/ep01/ep01_020101_01')"
                            @click="handleClick"
                        >
                          {{ t('component.Gnb.006') }}
                        </NuxtLink>
                      </li>
                      <li v-if="currentLanguage === 'en-US'">
                        <NuxtLink
                            :to="localePath('/story/ep01/ep01_020102_01')"
                            @click="handleClick"
                        >
                          {{ t('component.Gnb.007') }}
                        </NuxtLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NuxtLink
                      :to="localePath('/story/ep02/')"
                      @click="handleClick"
                    >{{ t('component.Gnb.008') }}</NuxtLink>
                    <ul>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep02/ep02_020201_01')"
                          @click="handleClick"
                        >{{ t('component.Gnb.009') }}</NuxtLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NuxtLink
                      :to="localePath('/story/ep03/')"
                      @click="handleClick"
                    >{{ t('component.Gnb.010') }}</NuxtLink>
                    <ul>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep03/ep03_020301_02')"
                          @click="handleClick"
                        >{{ t('component.Gnb.011') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep03/ep03_020301_03')"
                          @click="handleClick"
                        >{{ t('component.Gnb.012') }}</NuxtLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NuxtLink
                      :to="localePath('/story/ep04/')"
                      @click="handleClick"
                    >{{ t('component.Gnb.013') }}</NuxtLink>
                    <ul>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep04/ep04_020402_02')"
                          @click="handleClick"
                        >{{ t('component.Gnb.014') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep04/ep04_020402_03')"
                          @click="handleClick"
                        >{{ t('component.Gnb.015') }}</NuxtLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NuxtLink
                      :to="localePath('/story/ep05')"
                      @click="handleClick"
                    >{{ t('component.Gnb.016') }}</NuxtLink>
                    <ul>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep05/ep05_020500_02')"
                          @click="handleClick"
                        >{{ t('component.Gnb.017') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep05/ep05_020500_03')"
                          @click="handleClick"
                        >{{ t('component.Gnb.018') }}</NuxtLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NuxtLink
                      :to="localePath('/story/ep06')"
                      @click="handleClick"
                    >{{ t('component.Gnb.019') }}</NuxtLink>
                    <ul>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep06/ep06_020602_01')"
                          @click="handleClick"
                        >{{ t('component.Gnb.020') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep06/ep06_020603_01')"
                          @click="handleClick"
                        >{{ t('component.Gnb.021') }}</NuxtLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NuxtLink
                      :to="localePath('/story/ep07')"
                      @click="handleClick"
                    >{{ t('component.Gnb.022') }}</NuxtLink>
                    <ul>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep07/ep07_020702_01')"
                          @click="handleClick"
                        >{{ t('component.Gnb.023') }}</NuxtLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NuxtLink
                      :to="localePath('/story/ep08')"
                      @click="handleClick"
                    >{{ t('component.Gnb.024') }}</NuxtLink>
                    <ul>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep08/ep08_020802')"
                          @click="handleClick"
                        >{{ t('component.Gnb.025') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep08/ep08_020803')"
                          @click="handleClick"
                        >{{ t('component.Gnb.026') }}</NuxtLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NuxtLink
                      :to="localePath('/story/outro')"
                      @click="handleClick"
                    >{{ t('component.Gnb.027') }}</NuxtLink>
                  </li>
                </ul>
              </li>
            </ul>
          </Transition>
        </li>
        <li>
          <NuxtLink :to="localePath('webtoon')" class="fs_regular" @click="handleClick">
            {{ t('component.Gnb.028') }}
          </NuxtLink>
        </li>
        <li>
          <NuxtLink :to="localePath('game')" class="fs_regular" @click="handleClick">
            {{ t('component.Gnb.029') }}
          </NuxtLink>
        </li>
        <!-- 이하 생략 -->
      </ul>
      <div class="gnb_mo_bt_box">
        <div class="sel_lang_box_mo">
          <button
            type="button"
            class="lang_btn fs_regular"
            :class="{ on : isOpen.languageMo }"
            @click="isOpen.languageMo = !isOpen.languageMo"
          >{{ currentLanguageName }}</button>
          <div
            v-show="isOpen.languageMo"
            class="lang_sel"
          >
            <ul>
              <li
                v-for="lang in languageList"
                :key="lang.value"
              >
                <label>
                  <input
                    v-model="selectLanguage"
                    type="radio"
                    name="lang"
                    :value="lang.value"
                    @click="onSelectedLanguageMo"
                  >
                  <span>{{ lang.name }}</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <button
          type="button"
          class="gnb__share fs_regular"
          @click="openSharePopup"
        >{{ t('component.Gnb.031') }}</button>
      </div>
    </div>
    <div class="lo_pc">
      <div class="gnb_pc_tp">
        <ul>
          <li>
            <h2>{{ t('component.Gnb.001') }}</h2>
          </li>
          <li>
            <NuxtLink
              class="fs_regular"
              :to="localePath('/webtoon')"
              @click="closeGnb"
            >{{ t('component.Gnb.028') }}</NuxtLink>
          </li>
          <li>
            <NuxtLink
              class="fs_regular"
              :to="localePath('/game')"
              @click="closeGnb"
            >{{ t('component.Gnb.029') }}</NuxtLink>
          </li>
<!--          <li class="out_link_box">-->
<!--            <a-->
<!--              class="out_link fs_regular"-->
<!--              href="https://digitalownersmanual.hyundai.com/"-->
<!--              target="_blank"-->
<!--              @click="closeGnb"-->
<!--            >{{ t('component.Gnb.030') }}</a>-->
<!--          </li>-->
        </ul>
      </div>
      <div class="gnb_pc_bd">
        <ul>
          <li>
            <NuxtLink
              :to="localePath('/owners-world')"
              @click="closeGnb"
            >{{ t('component.Gnb.002') }}</NuxtLink>
          </li>
          <li>
            <NuxtLink
              :to="localePath('/story')"
              @click="closeGnb"
            >{{ t('component.Gnb.003') }}</NuxtLink>
            <div class="sub">
              <ul>
                <li>
                  <div class="detail">
                    <ul>
                      <li>
                        <NuxtLink
                          class="tit"
                          :to="localePath('/story/intro')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.004') }}</NuxtLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="detail">
                    <ul>
                      <li>
                        <NuxtLink
                          class="tit"
                          :to="localePath('/story/ep01')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.005') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep01/ep01_020101_01')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.006') }}</NuxtLink>
                      </li>
                      <li v-if="$i18n.locale == 'en-US'">
                        <NuxtLink
                          :to="localePath('/story/ep01/ep01_020102_01')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.007') }}</NuxtLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="detail">
                    <ul>
                      <li>
                        <NuxtLink
                          class="tit"
                          :to="localePath('/story/ep02')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.008') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep02/ep02_020201_01')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.009') }}</NuxtLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="detail">
                    <ul>
                      <li>
                        <NuxtLink
                          class="tit"
                          :to="localePath('/story/ep03')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.010') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep03/ep03_020301_02')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.011') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep03/ep03_020301_03')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.012') }}</NuxtLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="detail">
                    <ul>
                      <li>
                        <NuxtLink
                          class="tit"
                          :to="localePath('/story/ep04')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.013') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep04/ep04_020402_02')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.014') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep04/ep04_020402_03')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.015') }}</NuxtLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="detail">
                    <ul>
                      <li>
                        <NuxtLink
                          class="tit"
                          :to="localePath('/story/ep05')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.016') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep05/ep05_020500_02')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.017') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep05/ep05_020500_03')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.018') }}</NuxtLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="detail">
                    <ul>
                      <li>
                        <NuxtLink
                          class="tit"
                          :to="localePath('/story/ep06')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.019') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep06/ep06_020602_01')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.020') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep06/ep06_020603_01')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.021') }}</NuxtLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="detail">
                    <ul>
                      <li>
                        <NuxtLink
                          class="tit"
                          :to="localePath('/story/ep07')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.022') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep07/ep07_020702_01')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.023') }}</NuxtLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="detail">
                    <ul>
                      <li>
                        <NuxtLink
                          class="tit"
                          :to="localePath('/story/ep08')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.024') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep08/ep08_020802')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.025') }}</NuxtLink>
                      </li>
                      <li>
                        <NuxtLink
                          :to="localePath('/story/ep08/ep08_020803')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.026') }}</NuxtLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="detail">
                    <ul>
                      <li>
                        <NuxtLink
                          class="tit"
                          :to="localePath('/story/outro')"
                          @click="closeGnb"
                        >{{ t('component.Gnb.027') }}</NuxtLink>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>

          </li>
        </ul>
      </div>
      <div class="gnb_pc_ft">
        <ul>
          <li>
            <div class="sel_lang_box">
              <button
                class="btn_sel_lang"
                :class="{on : isOpen.languagePc}"
                @click="onSelectedLanguagePc"
              >{{ currentLanguageName }}</button>
              <div
                v-show="isOpen.languagePc"
                class="sel_lang_list"
              >
                <ul>
                  <li
                    v-for="lang in languageList"
                    :key="lang.value"
                  >
                    <label>
                      <input
                        v-model="selectLanguage"
                        type="radio"
                        name="langPc"
                        :value="lang.value"
                        @click="onSelectedLanguagePc"
                      >
                      <span>{{ lang.name }}</span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <button
              type="button"
              class="btn_share"
              @click="openSharePopup"
            >{{ t('component.Gnb.031') }}</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script setup>
const localePath = useLocalePath()
import { useSettingsStore } from '@/stores/settings'
import { useHeaderStore } from '@/stores/header'

const i18n = useI18n()
const { t } = useI18n()
const storeSetting = useSettingsStore()
const storeHeader = useHeaderStore()
const emit = defineEmits(['closeGnb'])

const isOpen = reactive({
  story: false,
  languageMo: false,
  languagePc: false
})

const selectLanguage = ref('')
const languageList = [
  {
    value: 'en-US',
    name: 'English(U.S)'
  },
  {
    value: 'de',
    name: 'Deutsch'
  },
  {
    value: 'fr',
    name: 'Français'
  },
  {
    value: 'no',
    name: 'Norsk'
  },
  {
    value: 'nl',
    name: 'Nederlands'
  },
  {
    value: 'es',
    name: 'Español'
  }
]

const currentLanguage = computed(() => i18n.locale.value)
const currentLanguageName = computed(() => {
  const lang = languageList.find((lang) => lang.value === currentLanguage.value)
  return lang ? lang.name : ''
})

watch(
  () => storeHeader.isShowGnb,
  (newVal) => {
    if (newVal) {
      document.body.classList.add('no-scroll')
    } else {
      setTimeout(() => {
        isOpen.story = false
        isOpen.languageMo = false
        isOpen.languagePc = false
        document.body.classList.remove("no-scroll")
      }, 300)
    }
  }
)

watch(selectLanguage, async (newVal) => {
  const lang = languageList.find((lang) => lang.value === newVal)
  if (lang) {
    await i18n.setLocale(lang.value)
  }
})

const onSelectedLanguageMo = () => {
  isOpen.languageMo = !isOpen.languageMo
}

const onSelectedLanguagePc = () => {
  isOpen.languagePc = !isOpen.languagePc
}

const handleClick = (event) => {
  event.preventDefault()
  closeGnb()
  navigateTo(event.currentTarget.getAttribute('href'))
}

const closeGnb = () => {
  emit('closeGnb')
}

const openSharePopup = () => {
  storeSetting.toggleSharePopup();
}
</script>